import { PopupButton } from '@typeform/embed-react'

import {useRef, useEffect} from "react";

const ContactUsForm = (closePopup) => {
  debugger

  const ref = useRef()

  useEffect(() => {
    if (ref.current) {
      ref.current.open(); // Open the PopupButton on component mount
    }
  }, []);

  return (
    <PopupButton ref={ref} id="WjQjuu1e" style={{ fontSize: 20 }} className="my-button">
    </PopupButton>
  )
}

export default ContactUsForm